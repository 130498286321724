<template>
  <div>
    <div class="d-flex">
      <vs-checkbox
          v-model="field.checked"
          color="primary"
          value="false"
      />
      <p class="simple-label-avenir">
        {{ field.label }}
      </p>
    </div>
    <div v-if="field.checked" class="d-flex mt-1">
      <vs-checkbox
          v-model="field.required"
          class="required-checkbox"
          color="danger"
          value="false"
      />
      <p class="simple-sub-label-avenir">
        {{ $t('views.playground.index.parameters.forms.form.participants-information.required') }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    field: {
      type: Object,
      default: () => {
      }
    }
  },
  created() {
    this.$vs.theme({
      primary: 'rgb(0, 165, 216)',
      danger: '#FF0101'
    })
  }
}
</script>
<style lang="scss" scoped>

/deep/ .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  font-family: 'Material Icons';
}

/deep/ .required-checkbox .vs-checkbox {
  width: 14px;
  height: 14px;
}
/deep/ .required-checkbox .vs-checkbox .vs-checkbox--check {
  height: 16px;
}

.required-checkbox {
  margin-left: 8.5px;
}
</style>
